:root {
  --root-font-size: 16px;
  --root-font-size-md: 14px;
  --root-font-size-sm: 13px;

  --container-width-primary: 82.44rem;
  --container-width-secondary: 78.4375rem;
  --container-responsive-width: 90%;

  --blue-1: #191653;
  --blue-2: #254dda;
  --blue-3: #5171e1;
  --blue-4: #d3e1fa;
  --blue-5: #ebf5fe;
  --blue-6: rgba(70, 107, 183, 0.14);

  --white: #fff;
  --black: #000;
  --black-1: #0D0D0E;

  --accent-purple: #191653;
  --accent-yellow: #ffc476;
  --accent-cyan: #0c1c1d;
  --accent-green: #519ea5;

  --light-gray: rgba(25, 22, 83, 0.6);
  --light-gray-1: rgba(235, 245, 254, 0.7);
  --light-gray-2: rgba(255, 255, 255, 0.7);

  --white: #fff;
  --white-10: rgba(255, 255, 255, 0.1);
  --white-19: rgba(255, 255, 255, 0.19);

  --gradient-1: linear-gradient(95deg, #6682e5 4.67%, #abbdff 101.74%);

  /* --primary-color: #191653;
  --secondary-color: #120f1f;
  --tertiary-color: #19152d;
  --white: #fff;
  --black: #000;
  --black-secondary: #101414;
  --highlight-2: #403b58;
  --dark-grey: #e3e3e3;
  --accent-green: #f3ffe9;
  --accent-peach: #fff0da;
  --accent-red: #ffe2e9;
  --accent-blue: #e3e9ff;
  --light-gray: #f6f6f6;
  --light-grey: #f6f6f6;
  --light-green: #eaffd9;

  --gradient-background: linear-gradient(108.54deg, #fffa80 8.95%, #bfb4fe 75.95%);
  --gradient-1: linear-gradient(135deg, #f3ffe9 0%, #d2ffaf 100%);
  --gradient-2: linear-gradient(135deg, #fff0da 0%, #ffdeab 100%);
  --gradient-3: linear-gradient(135deg, #ffe6ec 0%, #fecad7 100%);
  --gradient-4: linear-gradient(135deg, #e3e9ff 0%, #aabdff 100%);
  --gradient-5: linear-gradient(135deg, #e3fff8 0%, #b3ffed 100%);
  --gradient-6: linear-gradient(135deg, #f3e3ff 0%, #e1bbff 100%);
  --gradient-7: linear-gradient(91deg, #edffdf 1.34%, #d5ffb5 101.44%); */

  --radix-navigation-menu-viewport-width: 502px;
  --radix-navigation-menu-viewport-height: 399px;
}

/* 
.fill:before {
  content: "";
  position: absolute;
  background-image: linear-gradient(108.54deg, #fffa80 8.95%, #bfb4fe 75.95%);
  bottom: 0;
  left: 0;
  right: 100%;
  top: 0;
  z-index: -1;
  -webkit-transition: right 2s ease;
}

.fill:hover:before {
  right: 0;
}
*/

/* Extra small devices (phones, 576px and down) */
@media only screen and (max-width: 576px) {
}
/* Small devices (portrait tablets and large phones, 576px and up) */
@media only screen and (min-width: 576px) {
}
/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
}
/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) and (max-width: 1200px) {
}
