@import url('../assets/stylesheets/reset.css');
@import url('../assets/stylesheets/variables.css');
@import url('../v1/assets/stylesheets/_variables.css');

html,
body {
  font-family: var(--font-instrument-sans) !important;
}

.separator {
  height: 3px;
  width: 100%;
  background-color: var(--secondary-color);
}

.dot {
  font-size: 1rem;
  line-height: 1.5rem;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.text-center {
  text-align: center;
}

.p-1 {
  padding: 0.25rem;
}

.p-2 {
  padding: 0.5rem;
}

.p-3 {
  padding: 0.75rem;
}

.p-4 {
  padding: 1rem;
}

.px-1 {
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}

.px-2 {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.px-3 {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.py-1 {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}

.py-2 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.py-3 {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}

.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.py-10p {
  padding-top: 10px;
  padding-bottom: 10px;
}
.react-multi-carousel-dot-list {
  max-width: 80%;
}
.header1 {
  color: #fff;
  font-size: 4rem;
  font-style: normal;
  font-weight: 600;
  line-height: 4.13rem;
}
.header1Dark {
  color: var(--blue-1);
  font-size: 4rem;
  font-style: normal;
  font-weight: 600;
  line-height: 4.13rem;
}
.header2 {
  color: #fff;
  font-size: 3rem;
  font-weight: 600;
  line-height: 3.25rem;
  margin-bottom: 4rem;
}

.header2Dark {
  color: var(--blue-1);
  font-size: 3rem;
  font-weight: 600;
  line-height: 3.25rem;
}
.desc {
  color: var(--light-gray-1);
  text-align: center;
  font-size: 1.125rem;
  font-weight: 500;
  line-height: 1.75rem;
  margin-bottom: 20px;
}
b {
  font-weight: 600;
}

.textGradient {
  font-size: 14px;
  font-weight: 600;
  line-height: 1.8rem;
  text-transform: uppercase;
  background-image: var(--gradient-1);
  background-size: 100%;
  background-clip: text;
  -moz-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent;
}
/* .layout {
  max-width: 1224px;
  width: 100%;
  margin: 0 auto;
} */

@media only screen and (min-width: 1200px) {
  html,
  body {
    font-size: var(--root-font-size);
  }
}
@media only screen and (min-width: 800px) and (max-width: 1200px) {
  html,
  body {
    font-size: var(--root-font-size-md);
  }
}
@media only screen and (max-width: 800px) {
  html,
  body {
    font-size: var(--root-font-size-sm);
  }
}
