
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

* {
  margin: 0;
  padding: 0;
  font: inherit;
}

body {
  min-height: 100vh;
}

img,
picture,
svg,
video {
  display: block;
  max-width: 100%;
}

button {
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;
}

a {
  text-decoration: none;
  color: inherit;
}

ul {
  list-style: none;
}

:root {
  --root-font-size: 16px;
  --root-font-size-md: 14px;
  --root-font-size-sm: 13px;

  --container-width-primary: 82.44rem;
  --container-width-secondary: 78.4375rem;
  --container-responsive-width: 90%;

  --blue-1: #191653;
  --blue-2: #254dda;
  --blue-3: #5171e1;
  --blue-4: #d3e1fa;
  --blue-5: #ebf5fe;
  --blue-6: rgba(70, 107, 183, 0.14);

  --white: #fff;
  --black: #000;
  --black-1: #0D0D0E;

  --accent-purple: #191653;
  --accent-yellow: #ffc476;
  --accent-cyan: #0c1c1d;
  --accent-green: #519ea5;

  --light-gray: rgba(25, 22, 83, 0.6);
  --light-gray-1: rgba(235, 245, 254, 0.7);
  --light-gray-2: rgba(255, 255, 255, 0.7);

  --white: #fff;
  --white-10: rgba(255, 255, 255, 0.1);
  --white-19: rgba(255, 255, 255, 0.19);

  --gradient-1: linear-gradient(95deg, #6682e5 4.67%, #abbdff 101.74%);

  /* --primary-color: #191653;
  --secondary-color: #120f1f;
  --tertiary-color: #19152d;
  --white: #fff;
  --black: #000;
  --black-secondary: #101414;
  --highlight-2: #403b58;
  --dark-grey: #e3e3e3;
  --accent-green: #f3ffe9;
  --accent-peach: #fff0da;
  --accent-red: #ffe2e9;
  --accent-blue: #e3e9ff;
  --light-gray: #f6f6f6;
  --light-grey: #f6f6f6;
  --light-green: #eaffd9;

  --gradient-background: linear-gradient(108.54deg, #fffa80 8.95%, #bfb4fe 75.95%);
  --gradient-1: linear-gradient(135deg, #f3ffe9 0%, #d2ffaf 100%);
  --gradient-2: linear-gradient(135deg, #fff0da 0%, #ffdeab 100%);
  --gradient-3: linear-gradient(135deg, #ffe6ec 0%, #fecad7 100%);
  --gradient-4: linear-gradient(135deg, #e3e9ff 0%, #aabdff 100%);
  --gradient-5: linear-gradient(135deg, #e3fff8 0%, #b3ffed 100%);
  --gradient-6: linear-gradient(135deg, #f3e3ff 0%, #e1bbff 100%);
  --gradient-7: linear-gradient(91deg, #edffdf 1.34%, #d5ffb5 101.44%); */

  --radix-navigation-menu-viewport-width: 502px;
  --radix-navigation-menu-viewport-height: 399px;
}

/* 
.fill:before {
  content: "";
  position: absolute;
  background-image: linear-gradient(108.54deg, #fffa80 8.95%, #bfb4fe 75.95%);
  bottom: 0;
  left: 0;
  right: 100%;
  top: 0;
  z-index: -1;
  -webkit-transition: right 2s ease;
}

.fill:hover:before {
  right: 0;
}
*/

/* Extra small devices (phones, 576px and down) */
@media only screen and (max-width: 576px) {
}
/* Small devices (portrait tablets and large phones, 576px and up) */
@media only screen and (min-width: 576px) {
}
/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
}
/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) and (max-width: 1200px) {
}

:root {
  /* Primary Colors */
  --p-90: #070f2c;
  --p-80: #0f1f57;
  --p-70: #162e83;
  --p-60: #1e3eae;
  --p-50: #254dda;
  --p-40: #5171e1;
  --p-30: #7c94e9;
  --p-20: #a8b8f0;
  --p-10: #d3dbf8;

  /* Text Colors */
  --text-primary: #000041;
  --text-secondary: #66668d;
  --text-tertiary: #9999b3;

  /* Fill Colors */
  --fill-highlight-only: #fbfbfe;
  --fill-selection-only: #f6f8fe;

  /* Neutral Colors */
  --white: #ffffff;
  --black: #000000;

  /* Border Colors */
  --stroke-tabs-sections: #e5e9fb;
  --stroke-buttons-input: #d3dbf8;

  /* Link Colors */
  --link-rest: #0f72ee;
  --link-hover: #074da6;

  /* Positive Colors */
  --g-90: #0b2419;
  --g-80: #164832;
  --g-70: #206b4c;
  --g-60: #2b8f65;
  --g-50: #36b37e;
  --g-40: #5ec298;
  --g-30: #86d1b2;
  --g-20: #afe1cb;
  --g-10: #d7f0e5;

  /* Warning Colors */
  --w-90: #311f04;
  --w-80: #613e07;
  --w-70: #925e0b;
  --w-60: #c27d0e;
  --w-50: #f39c12;
  --w-40: #f5b041;
  --w-30: #f5b041;
  --w-20: #fad7a0;
  --w-10: #fdebd0;

  /* Info Colors */
  --i-90: #0a1e2c;
  --i-80: #153d58;
  --i-70: #1f5b83;
  --i-60: #2a7aaf;
  --i-50: #3498db;
  --i-40: #5dade2;
  --i-30: #85c1e9;
  --i-20: #aed6f1;
  --i-10: #d6eaf8;

  /* Dark Colors */
  --d-40: #888889;
  --d-50: #6a6a6c;

  /* Font Size */
  --font-size-h1: 64px;
  --font-size-h2: 48px;
  --font-size-h3: 36px;
  --font-size-h4: 28px;
  --font-size-h5: 24px;
  --font-size-h6: 18px;
  --font-size-h7: 16px;
  --font-size-h8: 14px;

  --font-size-28: 28px;
  --font-size-24: 24px;
  --font-size-20: 20px;
  --font-size-16: 16px;
  --font-size-18: 18px;
  --font-size-14: 14px;

  /* Line Height */
  --line-height-72: 72px;
  --line-height-56: 56px;
  --line-height-44: 44px;
  --line-height-32: 32px;
  --line-height-30: 30px;
  --line-height-28: 28px;
  --line-height-26: 26px;
  --line-height-24: 24px;
  --line-height-23: 23px;
  --line-height-22: 22px;
  --line-height-20: 20px;
  --line-height-18: 18px;

  /* Font Weight */
  --font-weight-400: 400;
  --font-weight-500: 500;
  --font-weight-600: 600;

  /* Computer Screen */
  --max-screen-width: 1300px;
}

.h1-v1 {
  font-size: var(--font-size-h1);
  font-weight: var(--font-weight-600);
  line-height: var(--line-height-72);
  letter-spacing: -0.4px;
}
.h2-v1 {
  font-size: var(--font-size-h2);
  font-weight: var(--font-weight-600);
  line-height: var(--line-height-56);
  letter-spacing: -0.4px;
}
.h3-v1 {
  font-size: var(--font-size-h3);
  font-weight: var(--font-weight-600);
  line-height: var(--line-height-44);
  letter-spacing: -0.4px;
}
.h4-v1 {
  font-size: var(--font-size-h4);
  font-weight: var(--font-weight-600);
  line-height: var(--line-height-32);
  letter-spacing: -0.4px;
}
.h5-v1 {
  font-size: var(--font-size-h5);
  font-weight: var(--font-weight-600);
  line-height: var(--line-height-32);
  letter-spacing: -0.2px;
}
.h6-v1 {
  font-size: var(--font-size-h6);
  font-weight: var(--font-weight-600);
  line-height: var(--line-height-24);
  letter-spacing: -0.2px;
}
.h7-v1 {
  font-size: var(--font-size-h7);
  font-weight: var(--font-weight-600);
  line-height: var(--line-height-20);
  letter-spacing: -0.2px;
}
.h8-v1 {
  font-size: var(--font-size-h8);
  font-weight: var(--font-weight-600);
  line-height: var(--line-height-18);
  letter-spacing: -0.2px;
}

.paragraph1-v1 {
  font-size: var(--font-size-20);
  font-weight: var(--font-weight-400);
  line-height: var(--line-height-28);
  letter-spacing: 0.2px;
}
.paragraph2-v1 {
  font-size: var(--font-size-16);
  font-weight: var(--font-weight-400);
  line-height: var(--line-height-24);
  letter-spacing: 0.2px;
}
.paragraph3-v1 {
  font-size: var(--font-size-14);
  font-weight: var(--font-weight-400);
  line-height: var(--line-height-20);
  letter-spacing: 0.2px;
}
.text-center {
  text-align: center;
}
.strong-text {
  font-weight: var(--font-weight-600);
}

@media screen and (max-width: 800px) {
  .h1-v1 {
    font-size: var(--font-size-h2);
    font-weight: var(--font-weight-600);
    line-height: var(--line-height-56);
    letter-spacing: -0.4px;
  }
  .h2-v1 {
    font-size: var(--font-size-h3);
    font-weight: var(--font-weight-600);
    line-height: var(--line-height-44);
  }
  .h3-v1 {
    font-size: var(--font-size-h4);
    font-weight: var(--font-weight-600);
    line-height: var(--line-height-32);
    letter-spacing: -0.4px;
  }
  .h4-v1 {
    font-size: var(--font-size-h5);
    font-weight: var(--font-weight-600);
    line-height: var(--line-height-32);
    letter-spacing: -0.2px;
  }

  .paragraph1-v1 {
    font-size: var(--font-size-16);
    font-weight: var(--font-weight-400);
    line-height: var(--line-height-24);
    letter-spacing: 0.2px;
  }
}

html,
body {
  font-family: var(--font-instrument-sans) !important;
}

.separator {
  height: 3px;
  width: 100%;
  background-color: var(--secondary-color);
}

.dot {
  font-size: 1rem;
  line-height: 1.5rem;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.text-center {
  text-align: center;
}

.p-1 {
  padding: 0.25rem;
}

.p-2 {
  padding: 0.5rem;
}

.p-3 {
  padding: 0.75rem;
}

.p-4 {
  padding: 1rem;
}

.px-1 {
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}

.px-2 {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.px-3 {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.py-1 {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}

.py-2 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.py-3 {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}

.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.py-10p {
  padding-top: 10px;
  padding-bottom: 10px;
}
.react-multi-carousel-dot-list {
  max-width: 80%;
}
.header1 {
  color: #fff;
  font-size: 4rem;
  font-style: normal;
  font-weight: 600;
  line-height: 4.13rem;
}
.header1Dark {
  color: var(--blue-1);
  font-size: 4rem;
  font-style: normal;
  font-weight: 600;
  line-height: 4.13rem;
}
.header2 {
  color: #fff;
  font-size: 3rem;
  font-weight: 600;
  line-height: 3.25rem;
  margin-bottom: 4rem;
}

.header2Dark {
  color: var(--blue-1);
  font-size: 3rem;
  font-weight: 600;
  line-height: 3.25rem;
}
.desc {
  color: var(--light-gray-1);
  text-align: center;
  font-size: 1.125rem;
  font-weight: 500;
  line-height: 1.75rem;
  margin-bottom: 20px;
}
b {
  font-weight: 600;
}

.textGradient {
  font-size: 14px;
  font-weight: 600;
  line-height: 1.8rem;
  text-transform: uppercase;
  background-image: var(--gradient-1);
  background-size: 100%;
  -webkit-background-clip: text;
          background-clip: text;
  -moz-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent;
}
/* .layout {
  max-width: 1224px;
  width: 100%;
  margin: 0 auto;
} */

@media only screen and (min-width: 1200px) {
  html,
  body {
    font-size: var(--root-font-size);
  }
}
@media only screen and (min-width: 800px) and (max-width: 1200px) {
  html,
  body {
    font-size: var(--root-font-size-md);
  }
}
@media only screen and (max-width: 800px) {
  html,
  body {
    font-size: var(--root-font-size-sm);
  }
}

/* latin-ext */
@font-face {
  font-family: 'Instrument Sans';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/e292f8757ca3fb64-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Instrument Sans';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/8de40de8211748f7-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: 'Instrument Sans';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/e292f8757ca3fb64-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Instrument Sans';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/8de40de8211748f7-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: 'Instrument Sans';
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/e292f8757ca3fb64-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Instrument Sans';
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/8de40de8211748f7-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: 'Instrument Sans';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/e292f8757ca3fb64-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Instrument Sans';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/8de40de8211748f7-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: 'Instrument Sans Fallback';src: local("Arial");ascent-override: 94.42%;descent-override: 24.33%;line-gap-override: 0.00%;size-adjust: 102.74%
}.__className_f21a63 {font-family: 'Instrument Sans', 'Instrument Sans Fallback';font-style: normal
}.__variable_f21a63 {--font-instrument-sans: 'Instrument Sans', 'Instrument Sans Fallback'
}

